import { useEffect } from 'react';
import { toast as showToast } from 'react-toastify';
import { Toast } from '@server/toast.server';

export function useToast(toast?: Toast | null) {
    useEffect(() => {
        if (toast) {
            setTimeout(() => {
                if (toast.type === 'success') {
                    successToast(toast.message);
                } else if (toast.type === 'error') {
                    errorToast(toast.message);
                } else if (toast.type === 'warn') {
                    warnToast(toast.message);
                } else if (toast.type === 'info') {
                    infoToast(toast.message);
                } else {
                    defaultToast(toast.message);
                }
            }, 0);
        }
    }, [toast]);
}

export function successToast(message: string) {
    showToast.success(message);
}

export function errorToast(message: string) {
    showToast.error(message);
}

export function infoToast(message: string) {
    showToast.info(message, {
        progressStyle: {
            backgroundColor: 'black',
        },
        style: {
            backgroundColor: '#57ef46',
        },
    });
}

export function warnToast(message: string) {
    showToast.warn(message);
}

export function defaultToast(message: string) {
    showToast(message, {
        progressStyle: {
            backgroundColor: 'black',
        },
    });
}
